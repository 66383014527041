import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import CeremonyRoll from "../components/CeremonyRoll";
import styled from "styled-components";
import flower from "../img/flower.svg";
import ContactForm from "../components/ContactForm";
import scrollTo from "../scrollToContact";

export const IndexPageTemplate = ({ image, title, subheading, mainpitch }) => {
  return (
    <>
      <Header>
        <img className="left" src={flower} />
        <img className="right" src={flower} />
        <div className="inner">
          <div className="text">
            <h1>{title}</h1>
            <em>{subheading}</em>
            <h2>{mainpitch.title}</h2>
            <p>{mainpitch.description}</p>
            <button onClick={scrollTo}>Get in touch</button>
          </div>
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      </Header>
      <Section>
        <img className="right" src={flower} />
        <CeremonyRoll />
      </Section>
      <Section>
        <img className="left" src={flower} />
        <ContactForm />
      </Section>
    </>
  );
};

const Header = styled.header`
  padding: 120px 0;
  background: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    background-color: #05784e;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    box-shadow: inset 0 -0.2rem rgba(0, 0, 0, 0.5),
      inset 0 0.2rem rgba(255, 255, 255, 0.2);
    color: var(--light);
  }

  .left {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
    width: 25%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }

  .right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }

  p {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
  }

  em {
    font-size: 24px;
    font-weight: 200;
    padding-bottom: 30px !important;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
  }

  .inner {
    display: flex;

    .text {
      margin-right: 20px;
    }

    .gatsby-image-wrapper {
      width: 200px;
      flex-shrink: 0;
      border-radius: 10px;

      @media (max-width: 760px) {
        display: none;
      }
    }
  }
`;

const Section = styled.div`
  width: 100vw;
  background-color: var(--light);
  padding: 60px 0;
  position: relative;

  .left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }

  .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    transform: rotate(180deg);
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }
`;

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
