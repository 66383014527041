import React from "react";
import styled from "styled-components";

const ContactForm = () => {
  return (
    <div className="inner">
      <h2 id="getInTouch">Get in touch</h2>
      <Form
        name="Contact form"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="Contact form" />

        <label htmlFor="name">
          <p>Your name</p>
        </label>
        <input type="text" name="name" id="name" required />

        <label htmlFor="email">
          <p>Your email</p>
        </label>
        <input type="email" name="email" id="email" required />

        <label htmlFor="message">
          <p>Message</p>
        </label>
        <textarea name="message" id="message" required />
        <button type="submit">Send</button>
      </Form>
    </div>
  );
};

const Form = styled.form`
  padding: 20px;
  background-color: white;
  background-color: var(--white);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

  h2 {
    margin-bottom: 20px;
  }

  input,
  textarea {
    display: block;
    margin-bottom: 20px;
    width: calc(100% - 20px);
    padding: 20px 10px;
    background-color: var(--light);
    outline: none;
    border: none;
    border-radius: 5px;
  }

  button {
    float: right;
    display: block;
    background-color: #05784e;
    color: white;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    box-shadow: inset 0 -0.2rem rgba(0, 0, 0, 0.5),
      inset 0 0.2rem rgba(255, 255, 255, 0.2);
  }
`;

export default ContactForm;
